import { useEffect, useState, Fragment } from "react";
import CollapsibleTable from "../../../../../components/ui/CollapsibleTable";
import MainCard from "../../../../../components/MainCard";
import { useLocation } from "react-router-dom";
import { brandRepresentHeader } from "../../../../../constants/OffAndOnline";
import { useSelector } from "react-redux";

const BrandRepresentTypes = () => {
  const [collapseContent, setCollapseContent] = useState([]);
  const [headerCells, setHeaderCells] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const { state } = useLocation();

  const getBrandRepActivities = useSelector((state) => state?.brandRepresentAct?.brandRepresentList);
  const getBrandRepActivitiesStatus = useSelector((state) => state?.brandRepresentAct?.brandRepresentLoading);


  useEffect(() => {
    if (state?.BrandRepActType?.marketingactivitytypename) {
      const typeName = state?.BrandRepActType?.marketingactivitytypename?.toLowerCase();
      const result = brandRepresentHeader.find((onH) => onH.typeName === typeName);
      if (result?.collapseH?.length) {
        const updatedWidth = result?.collapseH.map((ch) => {
          if (ch?.id === "attachments") {
            return ch;
          } else {
            return (ch = { ...ch, minWidth: ch.id === "description__c" ? 300 : 200 });
          }
        });
        setCollapseContent(updatedWidth);
      }

      setHeaderCells(result?.headers);
      setRowsData(state?.BrandRepActType?.customerActivities);
    }
  }, []);

  useEffect(() => {
    if (!getBrandRepActivitiesStatus) {
      if (getBrandRepActivities.activitytypes?.length) {
        const typeName = state?.BrandRepActType?.marketingactivitytypename?.toLowerCase();
        const data = getBrandRepActivities.activitytypes[0].activities;
        if (data?.length) {
          const filteredData = data.find((d) => d?.marketingactivitytypename?.toLowerCase() === typeName);
          setRowsData(filteredData?.customerActivities);
        }
      }
    }
  }, [getBrandRepActivities, getBrandRepActivitiesStatus]);
  const handleCallBack = (childData) => {
    // console.log({ childData });
  };
  return (
    <Fragment>
      <MainCard elevation={0} border={false} content={true}>
        <CollapsibleTable
          statusLoad={getBrandRepActivitiesStatus}
          rows={rowsData}
          headCells={headerCells}
          getFilterData={() => {}}
          isPagination={true}
          getAPICall={() => {}}
          collapseHeaderCells={collapseContent}
          getSelectedRowTable={handleCallBack}
        />
      </MainCard>
    </Fragment>
  );
};

export default BrandRepresentTypes;
