import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  approveMarketingActivityloading: false,
  approveMarketingActivityResponse: {},
  approveMarketingActivityError: false,
};

const approveMA = createSlice({
  name: "approveMA",
  initialState,
  reducers: {
    approveMarketingActivity(state) {
      return { ...state, approveMarketingActivityloading: true };
    },
    approveMarketingActivity_successful(state, action) {
      return {
        ...state,
        approveMarketingActivityResponse: action.payload,
        approveMarketingActivityloading: false,
      };
    },
    approveMarketingActivity_error(state, action) {
      return {
        ...state,
        approveMarketingActivityResponse: action.payload?.response,
        approveMarketingActivityloading: false,
        approveMarketingActivityError: true,
      };
    },
    resetStatus(state) {
      return {
        ...state,
        approveMarketingActivityloading: false,
        approveMarketingActivityError: false,
        approveMarketingActivityResponse: {},
      };
    },
  },
});

export const { approveMarketingActivity, approveMarketingActivity_successful, approveMarketingActivity_error, resetStatus } = approveMA.actions;

export default approveMA.reducer;
