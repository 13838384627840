import React,{ Fragment, useState, useEffect } from 'react';
import Forecastview from './ForecastView';
import { useDispatch, useSelector } from "react-redux";
import Authorization from "../../../../utils/authorization";
import util from "util";
import { apiURL } from "../../../../services/apiUrlConfig";
import {resetStatus } from "../../../../saga/saga/activitiesCommonSaga";
import  useActivitiesData from "./useActivitiesData"
import {SubHeaders} from "../MarketIntelligence/MarketIntelligence"

const CURRENTFINANCIAL_YEAR = 'fy_forecast__c'
const SECONDFINANCIAL_YEAR = 'fy_1_forecast__c'
const THIRDFINANCIAL_YEAR = 'fy_2_forecast__c'

const headCells = [
    {
      id: "varietyname",
      numeric: false,
      disablePadding: true,
      label: "Variety",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
    },
    {
      id: "productcode",
      numeric: false,
      disablePadding: false,
      label: "Forecast",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
  
    },
    {
      id: "fy_2_sell_out_target__c",
      numeric: true,
      disablePadding: true,
      label: "Target",
      minWidth: 150,
      isInputBox: false,
      isLast: false,
  
    },
    {
      id: "sellout",
      numeric: true,
      disablePadding: false,
      label: "Sell Out",
      minWidth: 150,
      isInputBox: false,
      isLast: false,
  
    },
    {
      id: "sales_inventory__c",
      numeric: true,
      disablePadding: false,
      label: "Inventory",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
  
    }
  ];

  export const statColsContent  = [
      
    { label: "Forecast",  id: "year", minWidth: 500, userInteration: false },
    { label: "Jan",  id: "January", minWidth: 500, userInteration: false },
    { label: "Feb", id: "February", minWidth: 500, userInteration: false },
    { label: "Mar",  id: "March", minWidth: 500, userInteration: false },
    { label: "Apr",  id: "April", minWidth: 500, userInteration: false },
    { label: "May",  id: "May", minWidth: 500, userInteration: false },
    { label: "Jun",  id: "June", minWidth: 500, userInteration: false },
    { label: "Jul",  id: "July", minWidth: 500, userInteration: false },
    { label: "Aug",  id: "August", minWidth: 500, userInteration: false },
    { label: "Sep",  id: "September", minWidth: 500, userInteration: false },
    { label: "Oct",  id: "October", minWidth: 500, userInteration: false },
    { label: "Nov",  id: "November", minWidth: 500, userInteration: false },
    { label: "Dec",  id: "December", minWidth: 500, userInteration: false },
  ]

  export default function FInfo() {
      const [dataSource,setDataSourrce] = useState([])
      const [collapseContent,setCollapseContent] = useState(statColsContent)
      const [totalCountMonths,setTotalCountMonths] = useState(0)
      const [nextdueDate,setNextDueDate] = useState('-')
      const dispatch = useDispatch();
      const getApi = apiURL.ppp.forecastActivities ?? "";
      const userDetaisl = Authorization.authUser;
      const getConditionalValues = useSelector(
        (state) => state.contracts.condsAndUncondsObj
      );
      
const activitisApi = util.format(getApi, userDetaisl.id);
const foreCastUrl = activitisApi+`?contractids=[${getConditionalValues?.contractId}]`+'&pppstage=ACTIVITIES';
const [dataApi] = useActivitiesData(foreCastUrl);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])

const wrapFilMonthsRow = async(src) => {
        const getonthsByYears = await monthsByYears(src)
        let finMonthRow = []
        finMonthRow = getonthsByYears&&getonthsByYears.map((item,_index) => {
            return  Months(src,item.finId,item.year)
        })
        return finMonthRow
    }
    
const monthsByYears = (src) => {
        return [{
            year : parseInt(src?.fiscal_year__c),
            finId : CURRENTFINANCIAL_YEAR
           },
           {
            year : parseInt(src?.fiscal_year__c) + 1,
            finId : SECONDFINANCIAL_YEAR
           },
           {
            year : parseInt(src?.fiscal_year__c) + 2,
            finId : THIRDFINANCIAL_YEAR
           }]
    }
    
const Months = (src,key,year) => {
        const skelMon = {
            label : 'Forecast',
            year: year,
            January : '-',
            February : '-',
            March : '-',
            April : '-',
            May : '-',
            June : '-',
            July : '-',
            August : '-',
            September : '-',
            October : '-',
            November : '-',
            December : '-',
            minWidth: 500,
            userInteration: false,
        }
        src&&src?.months.map((item,_index) => {
            skelMon[item.month_pl__c] = item[key] ?? '-'
        })
    return skelMon
} 
const RowSchema = async(src) =>{ 
    const dynoWrapFillMonth = await wrapFilMonthsRow(src)
    const resultForeCast = src?.months.reduce( ( sum, { fy_forecast__c } ) => sum + fy_forecast__c , 0)
    const resultTarget = src?.months.reduce( ( sum, { sales_target__c } ) => sum + sales_target__c , 0)
    const allMonthsTotal = src?.months.filter((ele)=>(ele?.fy_1_forecast__c === null || ele?.fy_2_forecast__c === null || ele?.fy_forecast__c === null))
    console.log("kjjdjdhhd::",allMonthsTotal)
    const RowVitExp =  {
    varietyname:src?.varietyname ?? '-',
    productcode:resultForeCast?? '-',
    fy_2_sell_out_target__c:resultTarget??'-',
    sellout:src?.fy_sell_out_target__c??'-',
    sales_inventory__c:src?.sales_inventory__c??'-',
    months: dynoWrapFillMonth,
    allMonthsTotal:allMonthsTotal
   }
   return RowVitExp
}  
const prepareResponse = (src) => {
          let PrepResp = []
          PrepResp = src && src.map((itm,_index) => {
              return RowSchema(itm)
          })
          return  PrepResp
      } 
useEffect(()=>{
  const resultForeCast = dataSource?.reduce( ( sum,  allmonthstotal  ) => sum + allmonthstotal?.allMonthsTotal?.length , 0)
  setTotalCountMonths(resultForeCast)
},[dataSource])  

useEffect(()=>{
   const resultPrepResp =  prepareResponse(dataApi?.body?.salestargets)
   const respForecast = dataApi?.body?.forecasts
   const forCastDates = respForecast?.length>0&&respForecast[0]?.dates
   var fromdate = new Date();
   const nxtAvailableDate = forCastDates?.length>0 && forCastDates.filter((ele)=>new Date(ele)>fromdate)
   const nextDate = (nxtAvailableDate?.length > 0 && nxtAvailableDate[0])??'-'
   setNextDueDate(nextDate)   
   resultPrepResp&&Promise.all(resultPrepResp).then((values) => {
       setDataSourrce(values)
      });
  },[dataApi])  
  
  return(
        <Fragment>
        <SubHeaders completedrRecords={totalCountMonths} totalRecords={dataSource?.length} dueDate={nextdueDate}/>
        <Forecastview pagination={false} multiLine ={true} multiLineSource={'months'} dataSource={dataSource} CollapseContent={collapseContent} headCells={headCells}></Forecastview>
        </Fragment>

    )
  }