import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  photoList: [],
  photoLoading: false,
  photoError: false,
};

const downloadPhoto = createSlice({
  name: "downloadPhoto",
  initialState,
  reducers: {
    getPhoto(state) {
      state.photoLoading = true;
      state.photoList = [];
    },
    getPhoto_successful(state, action) {
      state.photoList = action.payload.body;
      state.photoLoading = false;
    },
    getPhoto_error(state) {
      state.photoError = true;
    },
  },
});

export const { getPhoto, getPhoto_successful, getPhoto_error } = downloadPhoto.actions;

export default downloadPhoto.reducer;
