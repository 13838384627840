import { useEffect, useState, Fragment } from "react";
import { Typography } from "@mui/material";
import CollapsibleTable from "../../../../../components/ui/CollapsibleTable";
import MainCard from "../../../../../components/MainCard";
import { useLocation } from "react-router-dom";
import { offlineHeaders } from "../../../../../constants/OffAndOnline";
import { useSelector } from "react-redux";

// const ButtonS = () => {
//   const handleClick = () => {
//     // console.log("btn clicked?");
//   };
//   return <Button onClick={handleClick}>view download</Button>;
// };

const OfflineMarketingActivitiesTypes = () => {
  const [collapseContent, setCollapseContent] = useState([]);
  const [headerCells, setHeaderCells] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const { state } = useLocation();

  const getOfflineMarketingActivities = useSelector((state) => state.offlineMarketingAct?.offlineMAList);
  const getOfflineMarketingActivitiesStatus = useSelector((state) => state.offlineMarketingAct?.offlineMALoading);

  useEffect(() => {
    if (state?.offlineActType?.marketingactivitytypename) {
      const typeName = state?.offlineActType?.marketingactivitytypename?.toLowerCase();
      const result = offlineHeaders.find((onH) => onH.typeName === typeName);
      if (result?.collapseH?.length) {
        const updatedWidth = result?.collapseH.map((ch) => {
          if (ch?.id === "attachments") {
            return ch;
          } else {
            return (ch = { ...ch, minWidth: ch.id === "description__c" ? 300 : 200 });
          }
        });
        setCollapseContent(updatedWidth);
      }

      setHeaderCells(result?.headers);
      setRowsData(state?.offlineActType?.customerActivities);
    }
  }, []);

  useEffect(() => {
    if (!getOfflineMarketingActivitiesStatus) {
      if (getOfflineMarketingActivities.activitytypes?.length) {
        const typeName = state?.offlineActType?.marketingactivitytypename?.toLowerCase();
        const data = getOfflineMarketingActivities.activitytypes[0].activities;
        if (data?.length) {
          const filteredData = data.find((d) => d?.marketingactivitytypename?.toLowerCase() === typeName);
          setRowsData(filteredData?.customerActivities);
        }
      }
    }
  }, [getOfflineMarketingActivities, getOfflineMarketingActivitiesStatus]);
  const handleCallBack = (childData) => {
    // console.log({ childData });
  };
  return (
    <Fragment>
      {/* <Typography variant="h5" gutterBottom>
        {state?.data?.marketingactivitytypename}
      </Typography> */}
      <MainCard
        elevation={0}
        border={false}
        content={true}
        //   contentSX={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}
      >
        <CollapsibleTable
          statusLoad={getOfflineMarketingActivitiesStatus}
          rows={rowsData}
          headCells={headerCells}
          getFilterData={() => {}}
          isPagination={true}
          getAPICall={() => {}}
          collapseHeaderCells={collapseContent}
          getSelectedRowTable={handleCallBack}
        />
      </MainCard>
    </Fragment>
  );
};

export default OfflineMarketingActivitiesTypes;
