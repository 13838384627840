// Routes.js
// import { lazy, Suspense } from "react";
import { Suspense, useEffect } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
// import { Navigate } from "react-router-dom";
import { Childs } from "../constants";
import PrivateRoute from "./PrivateRoutes";
import Layout from "../layouts";
import PublicRoute from "./PublicRoutes";
import Toastr from "../components/ui/Toastr";
import FlashScreen from "./FlashScreen";
import Contracts from "../pages/PPP/Contracts";
import { ADMIN_ROLE, CMM_ROLE } from "../constants";
// const CreateContracts = lazy(() => import("../pages/PPP/Contracts/CreateContracts"))
import CreateContracts from "../pages/PPP/Contracts/CreateContracts";
import Dashboard from "../pages/Auth/Dashboard";
import Login from "../pages/Login";
import CustomMap from "../pages/Trial";
import PPPDashboard from "../pages/PPP/Dashboard/index";
import Analytics from "../pages/Analytics";
import DemandGeneration from "../pages/PPP/Contracts/Activities/Criteria/DemandGeneration/index";
import OfflineMarketing from "../pages/PPP/Contracts/Activities/Criteria/OfflineMarketingActivities/index";
import OnlineMarketing from "../pages/PPP/Contracts/Activities/Criteria/OnlineMarketingActivities/index";
import authorization from "../utils/authorization";
import OfflineMarketingList from "../pages/PPP/Activities/OfflineMarketingList";
import AccountActivitiesList from "../pages/PPP/Activities";
import ActivitiesAccounts from "../pages/PPP/Activities/ActivitiesAccounts";
import ActivityTypes from "../pages/PPP/Activities/ActivityTypes";
import OfflineMarketingActivitiesTypes from "../pages/PPP/Activities/OfflineMarketingList/Types";
import OnlineMarketingList from "../pages/PPP/Activities/OnlineMarketingList";
import OnlineMarketingActivitiesTypes from "../pages/PPP/Activities/OnlineMarketingList/Types";
import OrderManagementActivities from "../pages/PPP/Activities/OrderManagement"
import SalesVolume from "../pages/PPP/Activities/SalesVolume"
import ForecastInformation from "../pages/PPP/Activities/ForecastInformation"
import BrandRepresentList from "../pages/PPP/Activities/BrandRepresentList";
import BrandRepresentTypes from "../pages/PPP/Activities/BrandRepresentList/Types";
import LongTermBusinessActivities from "../pages/PPP/Activities/LongTermBusinessActivities"
import MarketIntelligence from "../pages/PPP/Activities/MarketIntelligence"
// const Dashboard = lazy(() => import("../pages/Auth/Dashboard"));
// const Login = lazy(() => import("../pages/Login"));
// const CustomMap = lazy(() => import("../pages/Trial"));
// const Analytics = lazy(() => import("../pages/Analytics"));
// const PPPContracts = lazy(() => import("../pages/PPP/Contracts"))
// const PPPDashboard = lazy(() => import("../pages/PPP/Dashboard/index"));
// const ConditionalUnconditional = lazy(() =>
//   import("../pages/PPP/Contracts/ConditionalUnconditional")
// );
// const Activities = lazy(()=> import("../pages/PPP/Contracts/Activities/index"));
// const ActivitiesCriteria = lazy(()=> import("../pages/PPP/Contracts/Activities/Criteria/index"));
// const DemandGeneration = lazy(() =>
//   import("../pages/PPP/Contracts/Activities/Criteria/DemandGeneration/index")
// );
// const OfflineMarketing = lazy(() =>
//   import(
//     "../pages/PPP/Contracts/Activities/Criteria/OfflineMarketingActivities/index"
//   )
// );
// const OnlineMarketing = lazy(() =>
//   import(
//     "../pages/PPP/Contracts/Activities/Criteria/OnlineMarketingActivities/index"
//   )
// );marketingactivitytypename

const AuthCheck = () => {
  const navigate = useNavigate();
  const isLoggedIn = authorization.isLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return null;
};

let routerN = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        {/* Private Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute roles={[ADMIN_ROLE, CMM_ROLE]}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/trial-location"
          element={
            <PrivateRoute roles={[ADMIN_ROLE]}>
              <CustomMap />
            </PrivateRoute>
          }
        />
        <Route
          path="/target-dashboard"
          element={
            <PrivateRoute roles={[ADMIN_ROLE]}>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path="/ppp-dashboard"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <PPPDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/contracts"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <Contracts />
            </PrivateRoute>
          }
        />
        <Route
          path="/editcontract"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <Contracts />
            </PrivateRoute>
          }
        />
        <Route
          path="/createContracts"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <CreateContracts />
            </PrivateRoute>
          }
        />
        <Route element={<AccountActivitiesList />}>
          <Route
            path={`/${Childs.ACCOUNTS}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <ActivitiesAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <ActivityTypes />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.OFFLINEMARKETING}/${Childs.ACTIVITYTYPE}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <OfflineMarketingActivitiesTypes />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.ONLINEMARKETING}/${Childs.ACTIVITYTYPE}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <OnlineMarketingActivitiesTypes />
              </PrivateRoute>
            }
          />
           <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.BRANDEXECUTION}/${Childs.ACTIVITYTYPE}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <BrandRepresentTypes />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.OFFLINEMARKETING}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <OfflineMarketingList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.ONLINEMARKETING}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <OnlineMarketingList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.ORDERMANAGEMENT}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <OrderManagementActivities />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.PAYMENTMANAGEMENT}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div>
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.FORECASTINFO}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <ForecastInformation/>
                {/* <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div> */}
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.MARKETINTELLIGENT}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <MarketIntelligence/>
                {/* <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div> */}
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.DEMANDGENERATIONTRIALS}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div>
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.LONGTERMBUSSINESSSOLUTION}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <LongTermBusinessActivities/>
                {/* <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div> */}
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.SALESVOLUME}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
              <SalesVolume />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.BRANDEXECUTION}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <BrandRepresentList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.POGDELIVERY}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div>
              </PrivateRoute>
            }
          />
          <Route
            path={`${Childs.ACCOUNTS}/${Childs.ACTIVITIES}/${Childs.PERFORMANCETRIALS}`}
            element={
              <PrivateRoute roles={[CMM_ROLE]}>
                <div style={{alignItems:'Center',display:'flex',justifyContent:'center',width:'100%',height:'100%',fontSize:15,color:'black'}}>Soon to be released</div>
              </PrivateRoute>
            }
          /> 
        </Route>

        <Route
          path="/demand-generation"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <DemandGeneration />
            </PrivateRoute>
          }
        />
        <Route
          path="/offline-marketing-activities"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <OfflineMarketing />
            </PrivateRoute>
          }
        />
        <Route
          path="/online-marketing-activities"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <OnlineMarketing />
            </PrivateRoute>
          }
        />
      </Route>

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      {/* URL is not applicable after redirect dashboard page */}
      <Route path="*" element={<div style={{ fontSize: 15 }}>{"Error in access! url not found."}</div>} />
      {/* <Route path="*" element={<div style={{fontSize:15}}>{'Error in access! url not found.'}</div>} /> */}
      <Route path="/" element={<AuthCheck />} />
    </>
  )
);

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<FlashScreen />}>
        <Toastr />
        <RouterProvider router={routerN}></RouterProvider>
      </Suspense>
    </>
  );
};

export default AppRoutes;
