import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivitiesCommon,resetStatus } from "../../../../saga/saga/activitiesCommonSaga";
import { openSnackbar } from "../../../../components/ui/Toastr";

const useFetch = (url) => {
      const [dataSource, setDataSourrce] = useState(null);
      const dispatch = useDispatch();
      const activitiesLoading = useSelector(
        (state) => state.activitiesCommonSaga.activitiesloading
      );
      const activitiesDetails = useSelector(
        (state) => state.activitiesCommonSaga.activitiesDetails
      );

useEffect(()=>{
        dispatch(getActivitiesCommon(url));
},[])

const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };

useEffect(()=>{
    if (activitiesLoading === false && !activitiesDetails) 
           {
            const errormsg = activitiesDetails?.message;
            const noNetworkMessage = 'Error Occured';
            const msg = activitiesDetails?.status ? errormsg : noNetworkMessage;
            popUpSuccessr(msg, "error");
            dispatch(resetStatus());
    }else if (activitiesDetails?.status === 200) {
            popUpSuccessr(activitiesDetails?.message, "success");
            setDataSourrce(activitiesDetails)
            dispatch(resetStatus());
    }
 },[activitiesLoading,activitiesDetails])

  return [dataSource];
};

export default useFetch;