import { useState, useEffect } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const YearSelect = ({ getValue }) => {
  const currentYear = new Date().getFullYear();
  const oldDataYear = new Date().getFullYear() - 1;
  let years = [];
  if (currentYear > oldDataYear) {
    years = Array.from({ length: currentYear - oldDataYear + 1 }, (_, index) => currentYear - index);
  } else {
    years = [oldDataYear];
  }

  const [selectedValue, setSelectedValue] = useState(currentYear);
  const { selectedYear } = useSelector((state) => state.common);

  useEffect(() => {
    if (selectedYear) setSelectedValue(selectedYear);
  }, [selectedYear]);

  // eslint-disable-next-line no-unused-vars
  const dropDownValues = years.map((year, index) => ({
    value: year,
    label: year,
  }));

  const handleSelect = (option) => {
    getValue(option?.value, "selectedYear");
    setSelectedValue(option?.value);
  };

  return (
    <Box>
      <ReactSelect
        name={"year"}
        options={dropDownValues}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="country-test"
        label="Year"
        width={250}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default YearSelect;
