import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activitiesDetails: {},
    activitiesloading: false,
    activitiesError: false,
    activities: [],
}

const userReducer = createSlice({

    name: 'activitiescommonsaga',
    initialState,
    reducers: {
        setActivitiesCommonData(state, action) {
            return {...state, activities : action.payload}
        },
        getActivitiesCommon(state) {
            return { ...state, activitiesloading: true,activities:[] };
        },
        getActivitiesCommon_successful(state, action) {
            return {
                ...state, activitiesDetails: action.payload,
                activitiesloading: false
            }
        },
        getActivitiesCommon_error(state, action) {
            return {
                ...state, activitiesDetails: action.payload.body,
                activitiesError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, activitiesloading: false,
                activitiesError: false,
                activitiesDetails: {},
            };
        },
    }
})

export const {
    getActivitiesCommon,
    getActivitiesCommon_successful,
    getActivitiesCommon_error,
    resetStatus,
    setActivitiesCommonData
} = userReducer.actions;

export default userReducer.reducer