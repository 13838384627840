import { Fragment, useEffect } from "react";
import MainCard from "../../../../components/MainCard";
// material-ui
import { Avatar, Grid, Box, Stack, Typography } from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import dayjs from "dayjs";
import Authorization from "../../../../utils/authorization";
import util from "util";
import { useDispatch, useSelector } from "react-redux";
import { getOfflineMA } from "../../../../saga/saga/offlineMarketingAct";
import { apiURL } from "../../../../services/apiUrlConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { Childs } from "../../../../constants";
import { CustomListItemText, BorderLinearProgress, LoaderSkeleton, CusTabs } from "../../../../components/ui/OffAndOnlineMAContent";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { getActivityProgress } from "../../../../utils/helper";

const ButtonS = () => {
  return <button>view download</button>;
};

const headerCells = [
  { label: "Description", value: "To have a meet up" },
  { label: "Attached File", value: <ButtonS /> },
];
const tabListData = [
  { value: 1, label: "Quarter 1" },
  { value: 2, label: "Quarter 2" },
  { value: 3, label: "Quarter 3" },
  { value: 4, label: "Quarter 4" },
];

const RenderCard = ({ isLoading, offlineList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const crdData = location.state?.data;
  // console.log({crdData})

  const redirectToTable = (obj) => {
    const rtpath = `${Childs.ACTIVITYTYPE}`
    const title = obj?.marketingactivitytypename
    const titlepath = `${location?.pathname}/${rtpath}`
    navigate(rtpath, {
      state: {
        data: {...obj,...crdData},
        title : {...location?.state?.title,[titlepath]:title},
        obj:[obj?.customerActivities[0]?.activity_program_name__c??crdData?.activityname,obj?.marketingactivitytypename],
        activities:{...crdData},
        offlineActType:{...obj}
      },
    });
  };
  const getActProgressCount = (offList) => {
    let progressCount = 0;
    if (offList?.customerActivities?.length > 0) {
      const filterProgressCount = offList?.customerActivities.filter((ol) => ol?.status__c?.toLowerCase() === "approved");
      progressCount = filterProgressCount?.length;
    }
    const output = getActivityProgress(progressCount, offList?.targetnumber);
    return output;
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {!isLoading ? (
        offlineList?.length > 0 ? (
          offlineList.map((offList) => {
            const progressCount = getActProgressCount(offList);
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={offList?.marketingactivitytypeid}
                sx={{ cursor: "pointer" }}
                onClick={() => redirectToTable(offList)}
              >
                <MainCard
                  title={offList?.marketingactivitytypename}
                  elevation={0}
                  border={false}
                  content={true}
                  contentSX={{ p: 1.5 }}
                  headerSX={{ p: 1.5, color: "#323232" }}
                  subheader="Activity Type"
                  avatar={
                    <Avatar aria-label="Field" sx={{ backgroundColor: "#ebf0f2" }}>
                      <ListAltOutlinedIcon sx={{ color: "#0091DF" }} />
                    </Avatar>
                  }
                >
                  <Box>
                    <Stack direction="row" spacing={5} mb={1}>
                      <CustomListItemText
                        primaryText="Start Date"
                        secondaryText={offList?.startdate ? dayjs(offList?.startdate).format("DD/MM/YYYY") : ""}
                      />
                      <CustomListItemText
                        primaryText="End Date"
                        secondaryText={offList?.enddate ? dayjs(offList?.enddate).format("DD/MM/YYYY") : ""}
                      />
                      <CustomListItemText primaryText="Target Number" secondaryText={offList?.targetnumber} />
                    </Stack>
                    <Stack direction="row" mb={1}>
                      <CustomListItemText primaryText="Qualitative Parameters" secondaryText={offList?.qualitativeparams} />
                    </Stack>
                    <Box>
                      <Typography sx={{ color: "#96A8AE", fontSize: "0.75rem" }}>Activity Progress</Typography>
                      <Box display="flex" sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"} gap={1}>
                        <Box sx={{ width: "85%" }}>
                          <BorderLinearProgress variant="determinate" value={progressCount}></BorderLinearProgress>
                        </Box>
                        <Box sx={{ width: "15%" }}>
                          <Typography sx={{ color: "#89D329" }} variant="subtitle1">
                            {progressCount}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MainCard>
              </Grid>
            );
          })
        ) : (
          <Grid item lg={12} textAlign={"center"}>
            <MainCard elevation={0} border={true} content={true} contentSX={{ p: 2 }}>
              No records found!
            </MainCard>
          </Grid>
        )
      ) : (
        <LoaderSkeleton />
      )}
    </Grid>
  );
};

const QuarterTypesContent = ({ value, offlineList, isLoading }) => {
  dayjs.extend(quarterOfYear);
  let filterByQuarter = [];
  if (offlineList?.length > 0) {
    const data = offlineList.map((ol) => {
      return (ol = { ...ol, quarterCount: dayjs(ol?.startdate).quarter() });
    });
    filterByQuarter = data?.length > 0 ? data.filter((da) => da?.quarterCount === value) : [];
  }
  return <RenderCard isLoading={isLoading} offlineList={filterByQuarter} />;
};
const OfflineMarketingList = () => {
  //const
  const authUser = Authorization.getAuthUser();
  //redux
  const dispatch = useDispatch();
  //routes
  const { state } = useLocation();
  const getOfflineMarketingActivities = useSelector((state) => state.offlineMarketingAct?.offlineMAList);
  const getOfflineMarketingActivitiesStatus = useSelector((state) => state.offlineMarketingAct?.offlineMALoading);
  const { viewContractID } = useSelector((state) => state.common);
  useEffect(() => {
    getLoadData();
  }, []);

  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getOneMarketingActivities, authUser?.id, viewContractID);
    const queryParams = `?activityTypeId=${state?.data?.id}&pppstage=${"ACTIVITIES"}`;
    dispatch(getOfflineMA(condUrl + queryParams));
  };

  const renderQuarterTypesContent = (value) => {
    return <QuarterTypesContent value={value} offlineList={offlineList} isLoading={getOfflineMarketingActivitiesStatus} />;
  };

  const tabPanelData = () => {
    return [
      { value: 1, content: renderQuarterTypesContent(1) },
      { value: 2, content: renderQuarterTypesContent(2) },
      { value: 3, content: renderQuarterTypesContent(3) },
      { value: 4, content: renderQuarterTypesContent(4) },
    ];
  };

  const offlineList = getOfflineMarketingActivities?.activitytypes?.length > 0 ? getOfflineMarketingActivities?.activitytypes[0].activities : [];
  return (
    <Fragment>
      <CusTabs tabListData={tabListData} tabPanelData={tabPanelData()} isScrollable={true}></CusTabs>
    </Fragment>
  );
};

export default OfflineMarketingList;
