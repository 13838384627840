/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
// import TableFooter from "@mui/material/TableFooter";
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CustomButton } from "../../../../components/ui/Button";
import DocumentCreate from "./DocumentCreate";
// import Avatar from '@mui/material/Avatar';
import Menu from "@mui/material/Menu";

import edit from "../../../../assets/images/edit.svg";
import menu from "../../../../assets/images/menu.png";
import reset from "../../../../assets/images/reset.png";

import { getContracts } from "../../../../saga/saga/createdContractSaga";
import Authorization from "../../../../utils/authorization";
import util from "util";
import { apiURL } from "../../../../services/apiUrlConfig";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Loader from "../../../../components/ui/Loader";
import { openSnackbar } from "../../../../components/ui/Toastr";
import UploadDocument from "./UploadDocument";
import { useNavigate } from "react-router-dom";
import { getContractActionType, getSelectedDocumentData } from "../../../../store/reducers/common";
import { setForecastSelection, getAccountsSelected, setCondsAndUncondsObj } from "../../../../store/reducers/contracts";
import { getActivities } from "../../../../saga/saga/activitiesSaga";
import { resetStatus, createContracts } from "../../../../saga/saga/contractSaga";

const TITLE = "Create Document";
const PGELIMIT = 10;
const TITLETWO = "View Document";
const SUCCESS = 200;

const pyLevels = [
  { value: "1", label: "Level 1" },
  { value: "2", label: "Level 2" },
  { value: "3", label: "Level 3" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00617F",
    color: theme.palette.common.white,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 12,
    fontWeight: 100,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 9,
    fontWeight: 100,
  },
}));
const CustomMenu = styled(MenuItem)({
  height: 30,
  fontSize: 11,
  letterSpacing: 0.5,
});
const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    display: "flex",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledCardFilter = styled(Card)(({ theme }) => ({
  "& .MuiCardContent-root": {
    paddingBottom: 0,
  },
}));

function createData(
  account,
  order,
  payment,
  forCast,
  marketInt,
  demandGen,
  offiline,
  online,
  longTerm,
  sales,
  brandRepre,
  pogDelivery,
  perfTrails,
  accId,
  itmObj,
  subActivities
) {
  return {
    account,
    1: order,
    2: payment,
    3: forCast,
    4: marketInt,
    5: demandGen,
    6: offiline,
    7: online,
    8: longTerm,
    9: sales,
    10: brandRepre,
    11: pogDelivery,
    12: perfTrails,
    accId,
    itmObj,
    subActivities,
  };
}

const FilterField = (props) => {
  const handleChange = (event) => {
    props.selectedValue(event.target.value);
  };

  return (
    <Box elevation={0} sx={{ display: "flex" }} style={{ flexDirection: "column" }}>
      <Typography
        style={{ paddingLeft: 0, display: "flex", alignItem: "center", justifyContent: "left", fontSize: "11px", letterSpacing: 0.5 }}
        component="label"
      >
        {props.title}
      </Typography>
      <FormControl sx={{ mr: 2, minWidth: 170 }} size="small">
        <StyledSelect
          name={props.name}
          labelId={props.name}
          id={props.name}
          value={props.value}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          style={{ width: 170, fontSize: 11, display: "flex", letterSpacing: 0.5 }}
          sx={{ display: "flex" }}
        >
          <CustomMenu value={"all"}>{props?.allLabel}</CustomMenu>
          {props.src?.length > 0 &&
            props.src.map((itm) => {
              return <CustomMenu value={itm.value}>{itm.label}</CustomMenu>;
            })}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

const FilterContentLayout = (props) => {
  const applyFilter = () => {
    props.applyFilter();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid style={{ height: "100%", width: "100%", border: "0px solid red" }} container spacing={0}>
        <Grid sx={{ pt: 2 }} DashboardItem xs={12}>
          <Paper elevation={0} sx={{ display: "flex" }}>
            <FilterField
              name={"country"}
              value={props.country}
              selectedValue={(value) => props.countrySelect(value)}
              src={props.dropdownCountryOptions}
              title="Country"
              allLabel="All country"
            />
            <FilterField
              name={"level"}
              value={props.level}
              selectedValue={(value) => props.levelSelect(value)}
              src={pyLevels}
              title="By PY level"
              allLabel="All PY level"
            />
            {/* <Button style={{borderRadius:25,height:30,width:90,marginTop:20,textTransform:'none',fontSize:12,letterSpacing:0.5}} variant="contained" endIcon={<NavigateNextIcon />}>
            Apply
           </Button> */}
            <CustomButton
              label="Apply"
              sx={{
                marginTop: 2,
                color: "#fff",
                borderRadius: "28px",
                backgroundColor: "rgb(0, 145, 223)",
                fontSize: 11,
                opacity: "1",
                "&:hover": {
                  backgroundColor: "#0D47A1",
                  // border: "1.35px solid #66B512",
                },
                width: "85px",
                marginLeft: "10px",
                height: "30px",
              }}
              handleClick={() => applyFilter()}
              className="cus-btn-arrow"
              labelClassName={"label"}
              labelSX={{
                fontSize: 12,
                fontWeight: "bold",
                letterSpacing: 0.3,
              }}
            ></CustomButton>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const extractActvitiesFromAccount = (accountInfo, existingObj) => {
  let obj = { ...existingObj };
  switch (accountInfo.activitytypeid) {
    case 1:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 2:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 3:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 4:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 5:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 6:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 7:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 8:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 9:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 10:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 11:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    case 12:
      obj[accountInfo.activitytypeid] = accountInfo.conditionaldiscountpercent;
      break;
    default:
      break;
  }
  return obj;
};

const setContractOrder = (contracts) => {
  let ActivityData = [];

  ActivityData =
    contracts?.length > 0 &&
    contracts.map((itm) => {
      let accountName = itm?.accountname || "";
      let accountId = itm?.id || 0;
      let activityObj = {};
      itm?.activitytargets?.length > 0 &&
        itm.activitytargets.forEach((element) => {
          activityObj = extractActvitiesFromAccount(element, activityObj);
        });
      return createData(
        accountName,
        activityObj[1] || 0,
        activityObj[2] || 0,
        activityObj[3] || 0,
        activityObj[4] || 0,
        activityObj[5] || 0,
        activityObj[6] || 0,
        activityObj[7] || 0,
        activityObj[8] || 0,
        activityObj[9] || 0,
        activityObj[10] || 0,
        activityObj[11] || 0,
        activityObj[12] || 0,
        accountId,
        itm,
        itm.activitytargets || []
      );
    });
  // console.log("sksksooem5432::",ActivityData)
  return ActivityData;
};

export default function CreateContract() {
  const [open, setOpen] = useState(false);
  const [mObj, setMobj] = useState({});
  const [openUploadView, setOpenUploadView] = useState(false);

  const [createdContracts, setCreatedContracts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const Popopen = Boolean(anchorEl);

  // eslint-disable-next-line no-unused-vars
  const [totalPages, settotalPages] = useState();
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(PGELIMIT);
  const [index, setIndex] = useState(0);
  const [numberOfpages, setNumberOfPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getApi = apiURL.ppp.getCreatedContracts ?? "";
  const userDetaisl = Authorization.authUser;
  const getCreatedList = util.format(getApi, userDetaisl.id);
  const [level, setLevel] = useState("all");
  const [country, setCountry] = useState("all");
  const [rowData, setRowData] = useState([]);
  const limitIndex = `&index=${index}&limit=${limit}`;
  const [expanded, setExpanded] = useState(false);
  const [headCells, setHeadCells] = useState([]);
  const createdContractList = useSelector((state) => state.createdContractSaga.contractsList);
  const createdContractStatus = useSelector((state) => state.createdContractSaga.contractsloading);
  const createdContractError = useSelector((state) => state.createdContractSaga.contractError);

  const createContractStatus = useSelector((state) => state.contractSaga.createContractsloading);
  const activitiesCreated = useSelector((state) => state.contractSaga.contractCreatedResponse);
  const contractCreatedError = useSelector((state) => state.contractSaga.contractCreatedError);

  const activitiesSelectedList = useSelector((state) => state);
  const activitiesList = useSelector((state) => state.activitiesSaga.activitiesList);
  const { countryList } = useSelector((state) => state.common);
  const [dropdownCountryOptions, setDropdownCountryOptions] = useState([]);

  const getActivitiesApi = apiURL.ppp.getActivities ?? "";
  const userId = Authorization.authUser;
  const activitisApi = util.format(getActivitiesApi, userId.id);
  const [fltValidation, setFltValidation] = useState("");

  const getCountryDetails = () => {
    if (countryList?.length > 0) {
      const selectOptions = countryList.map((rst) => {
        let obj = { value: rst?.countrycode, label: rst?.countryname };
        return obj;
      });
      const updatedOptions = [...selectOptions];
      setDropdownCountryOptions(updatedOptions);
    }
  };

  useEffect(() => {
    setCreatedContracts([]);
    dispatch(getContracts(getCreatedList + limitIndex + fltValidation));
    dispatch(getAccountsSelected([]));
    dispatch(getActivities(activitisApi));
  }, []);

  useEffect(() => {
    setCreatedContracts([]);
    dispatch(getContracts(getCreatedList + limitIndex + fltValidation));
  }, [index, limit]);

  useEffect(() => {
    getCountryDetails();
  }, [countryList]);

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    const activities =
      (activitiesList?.length > 0 &&
        activitiesList.map((itm) => {
          return { name: itm.name, id: itm.id };
          // eslint-disable-next-line no-mixed-operators
        })) ||
      [];
    setHeadCells(activities);
  }, [activitiesList]);

  const extractContractInfo = async (contracts) => {
    const updatedActivityList = await setContractOrder(contracts);
    // console.log("sksksooem2233::",updatedActivityList)
    setCreatedContracts(updatedActivityList);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!createdContractStatus) {
      const contracts = createdContractList?.contracts;
      const pagesCount = createdContractList?.count || 0;
      const noPages = Math.floor(pagesCount / PGELIMIT);
      setNumberOfPages(noPages);
      setCreatedContracts(contracts);
      settotalPages(createdContractList?.count);
      if (contracts?.length > 0) extractContractInfo(contracts);
    }
  }, [createdContractStatus, createdContractList]);

  const openCreate = async (value, row) => {
    const getDocStatus = await getDocState(row?.itmObj)?.options;
    if (getDocStatus === "UD" && !openUploadView) {
      setOpenUploadView(!openUploadView);
      dispatch(getSelectedDocumentData({ documentData: row }));
      setRowData(row);
    } else {
      setRowData(row);
      setOpen(!value);
    }
  };

  const viewUploadClose = () => {
    setOpenUploadView(false);
  };

  const LoadWhile = () => {
    return createdContractStatus ? (
      [...Array(4)].map((item, itemIndex) => {
        return (
          <TableRow key={itemIndex}>
            <TableCell
              colSpan={headCells?.length + 3}
              sx={{ textAlign: "center" }}
              style={{
                borderBottom: "0px solid transparent",
                paddingBottom: 0.5,
                paddingRight: 2,
                paddingLeft: 0,
              }}
            >
              <Skeleton animation="wave" variant="rectangular" sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }} />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
          {" "}
          No records found!
        </TableCell>
      </TableRow>
    );
  };

  const editAction = (obj) => {
    const objItm = obj.listObj;
    dispatch(
      setCondsAndUncondsObj({
        condsAndUncondsObj: {
          conditionalValue: 0,
          uncValue: 0,
          cDiscount: objItm?.conditionaldiscountpercent || 0,
          uncDiscount: 0,
          accoundID: objItm.accountid || 0,
          totalPYsales: objItm?.pysales || 0,
          targetSum: objItm?.targetvalue || 0,
          error: {},
          contractCountryCode: objItm?.countrycode || "",
        },
      })
    );
    dispatch(getContractActionType({ type: "EDIT", contratId: obj?.accId || 0, step: 3 }));
    dispatch(getAccountsSelected({ accounts: [...obj.subActivities] }));
    dispatch(setForecastSelection([]));
    setAnchorEl(null);
    setMobj({});
    navigate("/editcontract");
  };

  const pageIndexChnge = (event, newPage) => {
    setIndex((newPage - 1) * PGELIMIT);
  };
  const levelSelect = (value) => {
    setLevel(value);
  };
  const countrySelect = (value) => {
    setCountry(value);
  };
  const applyFilter = () => {
    const countryParam = country !== "all" ? `&countrycode=${country}` : "";
    const levelParam = level !== "all" ? `&pylevel=Level ${level}` : "";
    const filter = countryParam + levelParam;
    setFltValidation(filter);
    dispatch(getContracts(getCreatedList + limitIndex + filter));
  };
  const clearFilter = () => {
    setCountry("all");
    setLevel("all");
    setFltValidation("");
    dispatch(getContracts(getCreatedList + limitIndex));
  };

  const editActionCallBck = (obj, svd) => {
    dispatch(getContracts(getCreatedList + limitIndex + fltValidation));
  };

  const handleClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setMobj(obj);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const contractResetSchema = () => {
    return {
      ids: [mObj.accId],
      iscontractreset: true,
    };
  };
  const resetAction = async () => {
    setAnchorEl(null);
    const resetContractRequestBody = await contractResetSchema();
    const getContrCreatApi = apiURL.ppp.createContract ?? "";
    const userDetls = Authorization.authUser;
    const createContractApi = util.format(getContrCreatApi, userDetls.id);
    dispatch(
      createContracts({
        url: createContractApi,
        body: resetContractRequestBody,
      })
    );
  };

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };

  const getDocState = (cond) => {
    if (cond?.iscontractsaved === false || cond?.iscontractsaved === null) {
      return { title: "Create Document", options: "CD" };
    } else if (cond?.iscontractsaved === true && (cond?.isdealarsigned === false || cond?.isdealarsigned === null)) {
      return { title: "Upload Document", options: "UD" };
    } else {
      return { title: "View Document", options: "VD" };
    }
  };

  const uploadDone = () => {
    setOpenUploadView(false);
  };
  const uploadCancel = () => {
    setOpenUploadView(false);
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (createContractStatus == false && !activitiesCreated) {
      const errormsg = activitiesCreated?.message;
      const noNetworkMessage = "Please check internet or try again!";
      const msg = activitiesCreated?.status ? errormsg : noNetworkMessage;
      popUpSuccessr(msg, "error");
      dispatch(resetStatus());
      // eslint-disable-next-line eqeqeq
    } else if (activitiesCreated?.status == SUCCESS) {
      popUpSuccessr(`Contract has been reset successfully`, "success");
      dispatch(getContracts(getCreatedList + limitIndex + fltValidation));
      dispatch(resetStatus());
    }
  }, [createContractStatus, activitiesCreated]);

  return (
    <Fragment>
      {createContractStatus && <Loader />}
      {openUploadView && (
        <UploadDocument
          refreshAction={(saved) => editActionCallBck(saved)}
          close={() => viewUploadClose()}
          openViewDocument={(value, rowData) => openCreate(value, rowData)}
          rowData={rowData}
          title={"Upload Document"}
          open={openUploadView}
          cancel={() => uploadCancel()}
          ok={() => uploadDone()}
        />
      )}
      <Paper elevation={0} sx={{ width: "100%" }}>
        <StyledCardFilter elevation={0} sx={{ Width: "100%", fontSize: 13 }}>
          <CardContent sx={{ p: 0, display: "flex" }} style={{ paddingBottom: 3 }}>
            <Box elevation={0} sx={{ display: "flex", alignItems: "center" }}>
              <Typography elevation={0} component="div" variant="subtitle2" style={{ fontSize: 13, letterSpacing: 0.3 }}>
                Filter
              </Typography>
              <IconButton aria-label="previous">
                <ExpandMoreIcon onClick={handleExpandClick} />
              </IconButton>
            </Box>
            {expanded && (
              <Paper elevation={0} sx={{ width: "100%", display: "flex" }} style={{ alignItems: "center", justifyContent: "flex-end" }}>
                <Button style={{ fontStyle: 10 }} sx={{ textTransform: "none" }} onClick={() => clearFilter()}>
                  Clear Filters
                </Button>
              </Paper>
            )}
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={{ p: 0 }}>
              <FilterContentLayout
                level={level}
                country={country}
                levelSelect={(value) => levelSelect(value)}
                countrySelect={(value) => countrySelect(value)}
                dropdownCountryOptions={dropdownCountryOptions}
                applyFilter={() => applyFilter()}
              />
            </CardContent>
          </Collapse>
        </StyledCardFilter>
      </Paper>
      <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
        {open && (
          <DocumentCreate
            refreshAction={(saved) => editActionCallBck(saved)}
            editAction={() => editAction({ accId: rowData.accId, subActivities: rowData.subActivities, listObj: rowData.itmObj })}
            rowData={rowData}
            close={(value) => openCreate(value)}
            set={open}
          />
        )}
        <TableContainer style={{ overflow: "auto", height: 380 }}>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Popopen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem style={{ width: 130, backgroundColor: "transparent" }} onClick={() => editAction(mObj)}>
              <img src={edit} alt="" style={{ width: "22px", marginRight: 8, height: 17, borderRadius: 8 }} />{" "}
              <span style={{ fontSize: 13, color: "#262626", letterSpacing: 0.3 }}>Edit</span>
            </MenuItem>
            <MenuItem style={{ width: 150, backgroundColor: "transparent" }} onClick={() => resetAction()}>
              <img src={reset} alt="" style={{ width: "22px", marginRight: 8, height: 22, borderRadius: 8 }} />{" "}
              <span style={{ fontSize: 13, color: "#262626", letterSpacing: 0.3 }}>Reset</span>
            </MenuItem>
          </Menu>
          <Table sx={{ maxHeight: 380 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#00617F" }}>
                {headCells?.length > 0 && <StyledTableCell align="left">Accounts</StyledTableCell>}
                {headCells?.length > 0 &&
                  headCells.map((itm) => {
                    return <StyledTableCell align="left">{itm.name}</StyledTableCell>;
                  })}
                {headCells?.length > 0 && <StyledTableCell align="left">Actions</StyledTableCell>}
                {headCells?.length > 0 && <StyledTableCell align="left"></StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {createdContracts?.length > 0 && headCells?.length > 0 ? (
                createdContracts.map((row) => {
                  return (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        style={{ textWrap: row.account?.length >= 26 ? "balance" : "nowrap", paddingRight: 0 }}
                        component="th"
                        scope="row"
                      >
                        {row.account}
                      </StyledTableCell>
                      {headCells?.length > 0 &&
                        headCells.map((itm) => {
                          return <StyledTableCell align="left">{row[itm?.id] && row[itm?.id] + "%"}</StyledTableCell>;
                        })}
                      <StyledTableCell
                        title={
                          !row?.itmObj?.allactivitiesdefined ? "please submit all the activities to create document" : "click here to create document"
                        }
                        align="left"
                        style={{ textWrap: "nowrap" }}
                      >
                        <Button
                          disabled={getDocState(row?.itmObj)?.options === "CD" ? !row?.itmObj?.allactivitiesdefined : false}
                          onClick={() => openCreate(open, row)}
                          style={{ textTransform: "none", width: "70px", height: 10, fontSize: 11 }}
                        >
                          {getDocState(row?.itmObj)?.title}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell style={{ cursor: "pointer", width: "3.5%", paddingLeft: 2, paddingTop: 15 }} align="left">
                        {getDocState(row?.itmObj)?.options === "CD" && (
                          <img
                            onClick={(e) => handleClick(e, { accId: row.accId, subActivities: row.subActivities, listObj: row.itmObj })}
                            src={menu}
                            alt=""
                            style={{ width: 25, height: 25, borderRadius: 8 }}
                          />
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <LoadWhile />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ height: 50, width: "100%", display: "flex", justifyContent: "flex-end" }}
          count={numberOfpages}
          page={index}
          onChange={(event, value) => pageIndexChnge(event, value)}
        />
      </Paper>
    </Fragment>
  );
}
