import { put, takeLatest, all } from "redux-saga/effects";
import { getActivities, getActivities_successful, getActivities_error } from "./saga/activitiesSaga";
import { createContracts, createContracts_successful, createContracts_error } from "./saga/contractSaga";
import {
  getOrderDetails,
  updateOrderdetails,
  getOrderdetails_successful,
  getOrderdetails_error,
  setActivityData,
  setPaymentActivityData,
} from "./saga/orderMangeSaga";
import {
  updateSalesVolume,
  getSalesVolume_successful,
  getSalesVolume_error,
  getSalesVolume,
  setSalesVolumeActivityData,
} from "./saga/salesVolumeSaga";
import { getPaymentdetails, getPaymentdetails_successful, getPaymentdetails_error } from "./saga/paymentManageSaga";
import { getContracts, getContracts_successful, getContracts_error } from "./saga/createdContractSaga";
import { setforeCastActivityData, getforeCastInfo, updateforeCastInfo, getforeCastInfo_successful, getforeCastInfo_error } from "./saga/foreCastSaga";
import {
  getmarketIntelligence,
  getmarketIntelligence_successful,
  getmarketIntelligence_error,
  updatemarketIntelligence,
  setmarketIntelligenceActivityData,
} from "./saga/MarketIntelligenceSaga";

import {
  getActivitiesCommon,
  getActivitiesCommon_successful,
  getActivitiesCommon_error,
  setActivitiesCommonData,
} from "./saga/activitiesCommonSaga";


import { getAdvTrails, getadvTrails_successful, getadvTrails_error, setadvTrailsActivityData, updateAdvTrails } from "./saga/advancementTrails";
import { updatecreateDocument, getcreateDocument_successful, getcreateDocument_error } from "./saga/createDocumentSaga";
import { getRequest, saveOrUpdateRequest, uploadRequest } from "../services/apiUtils";

import { getDemandGeneration, getDemandGeneration_successful, getDemandGeneration_error } from "./saga/demandGenerationSaga";
import { getDocumentData, getDocument_successful, getDocument_error } from "./saga/documentUpload";
import { getSignedDocumentData, getSignedDocument_successful, getSignedDocument_error } from "./saga/uploadSignedDoc";
import { saveDocumentData, saveDocument_successful, saveDocument_error } from "./saga/documentSave";
import {
  // updateFilePath,
  getFilePath_successful,
  getFilePath_error,
  getFilePath,
  // setFilePath
} from "./saga/downloadFileSaga";

import { getOfflineMA, getOfflineMA_successful, getOfflineMA_error } from "./saga/offlineMarketingAct";
import { getOrderManagementAct, getOrderManagementAct_successful, getOrderManagementAct_error, resetStatus } from "./saga/orderManagementActSaga";
import { getOnlineMA, getOnlineMA_successful, getOnlineMA_error } from "./saga/onlineMarketingAct";
import { approveMarketingActivity, approveMarketingActivity_successful, approveMarketingActivity_error } from "./saga/approveMarketingActivity";
import { getPhoto ,getPhoto_successful,getPhoto_error} from "./saga/downloadPhotos";
import {getBrandRepresent,getBrandRepresent_successful,getBrandRepresent_error} from "./saga/brandRepresentAct";


const commonGetService = async (url) => {
  const res = await getRequest({
    url: url,
  });
  return res;
};

const commonPostService = async (src) => {
  const res = await saveOrUpdateRequest({
    method: "POST",
    url: src.url,
    data: src.body,
  });
  return res;
};
const commonUploadService = async (src) => {
  const res = await uploadRequest({
    method: "POST",
    url: src.url,
    data: src.body,
  });
  return res;
};
function* sagaService() {
  yield all([
    takeLatest(getActivities, fetchActivities),
    takeLatest(createContracts, contractCreation),
    takeLatest(updateOrderdetails, submitOrderDetails),
    takeLatest(updateSalesVolume, submitSalesVolume),
    takeLatest(getPaymentdetails, submitPaymentDetails),
    takeLatest(getOrderDetails, fetchOrderDetails),
    takeLatest(getSalesVolume, fetchSalesVolume),
    takeLatest(getContracts, fetchCreatedActivities),
    takeLatest(getforeCastInfo, fetchForeCastInfo),
    takeLatest(updateforeCastInfo, submitForeCastInfo),
    takeLatest(getmarketIntelligence, fetchMarketIng),
    takeLatest(updatemarketIntelligence, updateMarketIng),
    takeLatest(getDemandGeneration, submitDemandGeneration),
    takeLatest(updatecreateDocument, updateDocumentDetails),
    takeLatest(getDocumentData, documentUpload),
    takeLatest(saveDocumentData, documentSave),
    takeLatest(getSignedDocumentData, signedDocumentUpload),
    takeLatest(getFilePath, getDownlaodPdfPath),
    takeLatest(getAdvTrails, fetchAdvTrails),
    takeLatest(updateAdvTrails, updateAdvancementTrail),
    takeLatest(getOfflineMA, fetchOfflineMarketingActivities),
    takeLatest(getOnlineMA, fetchOnlineMarketingActivities),
    takeLatest(getOrderManagementAct, fetchOrderManagementActivities),
    takeLatest(approveMarketingActivity, submitApproveMarketingActivity),
    takeLatest(getPhoto, fetchAttachmentPhoto),
    takeLatest(getActivitiesCommon, fetchActivitiesCommon),
    takeLatest(getBrandRepresent, fetchBrandRepresentActivities),
  ]);
}

function* getDownlaodPdfPath(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getFilePath_successful(resp));
  } catch (e) {
    yield put(getFilePath_error(e));
  }
}

function* signedDocumentUpload(actions) {
  try {
    const resp = yield commonUploadService(actions.payload);
    yield put(getSignedDocument_successful(resp));
  } catch (e) {
    yield put(getSignedDocument_error(e));
  }
}
function* documentUpload(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getDocument_successful(resp));
  } catch (e) {
    yield put(getDocument_error(e));
  }
}
function* documentSave(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(saveDocument_successful(resp));
  } catch (e) {
    yield put(saveDocument_error(e));
  }
}
function* updateMarketIng(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getmarketIntelligence_successful(resp));
  } catch (e) {
    yield put(getmarketIntelligence_error(e));
  }
}
function* updateAdvancementTrail(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getadvTrails_successful(resp));
  } catch (e) {
    yield put(getadvTrails_error(e));
  }
}
function* updateDocumentDetails(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getcreateDocument_successful(resp));
  } catch (e) {
    yield put(getcreateDocument_error(e));
  }
}

function* fetchMarketIng(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    const responsebody = resp?.body;
    const listOfMi = responsebody?.list || [];
    yield all([yield put(getmarketIntelligence_successful(resp)), yield put(setmarketIntelligenceActivityData(listOfMi))]);
  } catch (e) {
    yield put(getmarketIntelligence_error(e));
  }
}
function* fetchAdvTrails(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    const responsebody = resp?.body;
    yield all([yield put(getadvTrails_successful(resp)), yield put(setadvTrailsActivityData(responsebody))]);
  } catch (e) {
    yield put(getadvTrails_error(e));
  }
}

function* fetchActivitiesCommon(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield all([yield put(getActivitiesCommon_successful(resp))]);
  } catch (e) {
    yield put(getActivitiesCommon_error(e));
  }
}
function* submitForeCastInfo(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getforeCastInfo_successful(resp));
  } catch (e) {
    yield put(getforeCastInfo_error(e));
  }
}

function* fetchForeCastInfo(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    const responsebody = resp?.body;
    const listOfVarieties = responsebody?.list || [];
    const foreCast = responsebody?.forecasts || [];

    yield all([yield put(setforeCastActivityData({ list: listOfVarieties, forecast: foreCast })), yield put(getforeCastInfo_successful(resp))]);
  } catch (e) {
    yield put(getforeCastInfo_error(e));
  }
}

function* fetchActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getActivities_successful(resp));
  } catch (e) {
    yield put(getActivities_error(e));
  }
}
function* fetchCreatedActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getContracts_successful(resp));
  } catch (e) {
    yield put(getContracts_error(e));
  }
}

function* contractCreation(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(createContracts_successful(resp));
  } catch (e) {
    yield put(createContracts_error(e));
  }
}
function* submitSalesVolume(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getSalesVolume_successful(resp));
  } catch (e) {
    yield put(getSalesVolume_error(e));
  }
}
function* submitOrderDetails(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getOrderdetails_successful(resp));
  } catch (e) {
    yield put(getOrderdetails_error(e));
  }
}
function* fetchSalesVolume(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    const responsebody = resp?.body;
    const respObj = {
      Basic: responsebody?.base,
      Silver: responsebody?.silver,
      Gold: responsebody?.gold,
      Platinum: responsebody?.platinum,
    };
    const submitBy = { ...respObj };

    yield all([put(setSalesVolumeActivityData(submitBy)), put(getSalesVolume_successful(resp))]);
  } catch (e) {
    yield put(getSalesVolume_error(e));
  }
}
function* fetchOrderDetails(actions) {
  try {
    const resp = yield commonGetService(actions.payload.url);
    const responsebody = resp?.body;
    if (actions.payload.isFor === "ORDER") {
      const orderValue = responsebody?.order;
      const submitBy = orderValue?.submitby;

      yield all([put(setActivityData(submitBy)), put(getOrderdetails_successful(resp))]);
    } else {
      const paymentValue = responsebody?.payment;
      const submitBy = paymentValue?.submitby;

      yield all([put(setPaymentActivityData(submitBy)), put(getOrderdetails_successful(resp))]);
    }
  } catch (e) {
    yield put(getOrderdetails_error(e));
  }
}
function* submitPaymentDetails(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getPaymentdetails_successful(resp));
  } catch (e) {
    yield put(getPaymentdetails_error(e));
  }
}
function* submitDemandGeneration(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(getDemandGeneration_successful(resp));
  } catch (e) {
    yield put(getDemandGeneration_error(e));
  }
}

function* fetchOfflineMarketingActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getOfflineMA_successful(resp));
  } catch (e) {
    yield put(getOfflineMA_error(e));
  }
}
function* fetchOnlineMarketingActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getOnlineMA_successful(resp));
  } catch (e) {
    yield put(getOnlineMA_error(e));
  }
}

function* fetchOrderManagementActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getOrderManagementAct_successful(resp));
  } catch (e) {
    yield put(getOrderManagementAct_error(e));
  }
}

function* submitApproveMarketingActivity(actions) {
  try {
    const resp = yield commonPostService(actions.payload);
    yield put(approveMarketingActivity_successful(resp));
  } catch (e) {
    yield put(approveMarketingActivity_error(e));
  }
}

function* fetchAttachmentPhoto(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getPhoto_successful(resp));
  } catch (e) {
    yield put(getPhoto_error(e));
  }
}

function* fetchBrandRepresentActivities(actions) {
  try {
    const resp = yield commonGetService(actions.payload);
    yield put(getBrandRepresent_successful(resp));
  } catch (e) {
    yield put(getBrandRepresent_error(e));
  }
}

export default sagaService;
