import React,{ Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Authorization from "../../../../utils/authorization";
import util from "util";
import {resetStatus } from "../../../../saga/saga/activitiesCommonSaga";
import  useActivitiesData from "../ForecastInformation/useActivitiesData"
import Forecastview from '../ForecastInformation/ForecastView';
import { apiURL } from "../../../../services/apiUrlConfig";
import {statColsContent as monthLabels} from "../ForecastInformation/ForeCastActivities"


const headCells = [
    {
      id: "smart_goal",
      numeric: false,
      disablePadding: true,
      label: "Activity",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
    },
    {
      id: "checkindate",
      numeric: false,
      disablePadding: false,
      label: "Quarter/Month",
      minWidth: 200,
      isInputBox: false,
      isLast: false,
  
    },
    {
      id: "submitbydate",
      numeric: true,
      disablePadding: true,
      label: "Due Date",
      minWidth: 150,
      isInputBox: false,
      isLast: false,
  
    }
  ];
  const dynoItem = (key,Item) =>{
       let itemCount = key.match(/\d+/)[0] // "3"
       return util.format(Item, itemCount)
  }
  const HEADCELL_1 = ['smart_goal_1__c','smart_goal_2__c','smart_goal_3__c','smart_goal_4__c']
  const COLLAPSE_1 = 'description_%s__c'
  const HEADCELL_2 = 'checkindate%s'
  const HEADCELL_3 = 'submitbydate%s'

  const prepResponse = (src) => {
    let PrepResp = []
    HEADCELL_1.forEach(item => {
      //  let dynoItem_1 = dynoItem(item,HEADCELL_2)
       let dynoItem_2 = dynoItem(item,HEADCELL_3)
       let collapseItem_1 = dynoItem(item,COLLAPSE_1)
       let findQrter = '-'
       if(src?.[dynoItem_2]){
       var today = new Date(src?.[dynoItem_2]);
       const month = today.toLocaleString('default', { month: 'long' });
       const monthLabel = monthLabels.filter((el)=>el.id === month)
       const Qmonth =  monthLabel?.length > 0 ? monthLabel[0]?.label : '-'
       findQrter = 'Q'+Math.floor((today.getMonth() + 3) / 3)+'/'+ Qmonth;
       }

        if(src?.[item])
        {
            PrepResp.push({
                'smart_goal' : src?.[item],
                'checkindate' : findQrter || '-',
                'submitbydate' : src?.[dynoItem_2] || '-',
                'description' : src?.[collapseItem_1] || '-'
            })
        }
        
    });
    return PrepResp
  }

  const statColsContent  = [
    { label: "Description",  id: "description", minWidth: 500, userInteration: false }
  ]

  export default function LongTermBusinessActivities() {
    const [dataSource,setDataSourrce] = useState([])
    const [collapseContent,setCollapseContent] = useState(statColsContent)
    const dispatch = useDispatch();
    const getApi = apiURL.ppp.longTermActivities ?? "";
    const userDetaisl = Authorization.authUser;
    const getConditionalValues = useSelector(
      (state) => state.contracts.condsAndUncondsObj
    );
    
const activitisApi = util.format(getApi, userDetaisl.id,getConditionalValues?.contractId);
const foreCastUrl = activitisApi+`?activityTypeId=7`+'&pppstage=ACTIVITIES';
// const DumpforeCastUrl = 'ppp/long_term_bsolution/157/41?activityTypeId=7&pppstage=ACTIVITIES'
const [dataApi] = useActivitiesData(foreCastUrl);

useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])

useEffect(()=>{
    const resultPrepResp =  prepResponse(dataApi?.body)
    setDataSourrce(resultPrepResp)
},[dataApi])  

return(
    <Forecastview pagination={false} multiLine ={false} multiLineSource={'null'} dataSource={dataSource} CollapseContent={collapseContent} headCells={headCells}></Forecastview>
)

  }