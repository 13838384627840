import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ordermanagement from "../../../../assets/images/ordermanagement.png";
import paymentmanagement from "../../../../assets/images/forecast.png";
import forecastinfo from "../../../../assets/images/paymentmanagement.png";
import marketintelligent from "../../../../assets/images/marketintelligence.png";
import demandgenerationtrials from "../../../../assets/images/demandgeneration.png";
import offlinemarketing from "../../../../assets/images/offlinemarketing.png";
import onlinemarketing from "../../../../assets/images/onlinemarketing.png";
import longtermbussinesssolution from "../../../../assets/images/longtermbusiness.png";
import salesvolume from "../../../../assets/images/salesvolume.png";
import brandexecution from "../../../../assets/images/edit.svg";
import pogdelivery from "../../../../assets/images/onlinemarketing.png";
import performancetrials from "../../../../assets/images/edit.svg";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate,useLocation} from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import {dynoNavigate as wrapRoute  } from "../../../../components/ActivityTypeController"

export const activityIds = {
    1: {ImgId:ordermanagement,ImgActivity:'ordermanagement'},
    2: {ImgId:paymentmanagement,ImgActivity:'paymentmanagement'},
    3: {ImgId : forecastinfo,ImgActivity : 'forecastinfo'},
    4: {ImgId : marketintelligent,ImgActivity : 'marketintelligent'},
    5: {ImgId : demandgenerationtrials,ImgActivity : 'demandgenerationtrials'},
    6: {ImgId : offlinemarketing,ImgActivity : 'offlinemarketing'},
    7: {ImgId : onlinemarketing,ImgActivity : 'onlinemarketing'},
    8: {ImgId : longtermbussinesssolution,ImgActivity : 'longtermbussinesssolution'},
    9: {ImgId : salesvolume,ImgActivity : 'salesvolume',salesvolume:'Sales Volume'},
    10:{ImgId : brandexecution,ImgActivity : 'brandexecution'},
    11: {ImgId : pogdelivery,ImgActivity : 'pogdelivery'},
    12: {ImgId : performancetrials,ImgActivity : 'performancetrials'},
  };

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

const ActivityCardView = (src) =>{
    return(
        <Paper elevation={0} style={{cursor:src?.enable?'pointer':'not-allowed',height:75,display:'flex',flexDirection:'column',backgroundColor:'transparent',paddingRight:15,paddingLeft:15,paddingTop:10}}>
            <img style={{cursor:src?.enable?'pointer':'not-allowed',width:20,height:17.5,paddingLeft:6}} src={activityIds[src?.imgId]?.ImgId} alt={activityIds[src?.imgId]?.ImgActivity} ></img>
            <label style={{cursor:src?.enable?'pointer':'not-allowed',display:'flex',justifyContent:'flex-start',textAlign:'left',paddingTop:5,fontWeight:600,letterSpacing:0.5,color:src?.enable?'#323232':'grey'}}>{src?.displyname}</label>
        </Paper>
    )
}
const LoadWhile = (props) => {
        return (
    props?.status ?<div>No Data Found</div>:<Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2.2}>
          {[...Array(12)].map(()=>{return(
            <Grid item xs={2.5}>
              <Item style={{backgroundColor:'#F2F2F2',borderColor:'#DCE8EB'}}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ height: 75, width: "100%", backgroundColor: "#F2F2F2" }}
               />
            </Item>
            </Grid>
          )})}
          </Grid>
        </Box>
    );
          }
    
const ItemView = ({ children, ...rest }) => (
            <Item {...rest}>
              {children}
            </Item>
          );     
const Activity = wrapRoute(ItemView);
export const BrView = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  function handleClick(crdData,dpName,loc,e) {
      e.preventDefault();
      navigate(`${loc}`, {
          state: {
            data: crdData,
            obj:dpName,
            title:{...location?.state?.title}
          },
        });
    }
  return <Breadcrumbs separator="›" aria-label="breadcrumb" style={{fontSize:9,fontWeight:'bold',letterSpacing:0.4,color:'black'}}>
      {props.paths?.length>1&&props.paths.map((item,index)=>{
          let loc = `/${props.paths.slice(0, index + 1).join('/')}`;
          console.log("findissue:::",props,index)
          return <Link underline="hover" key={index} color="inherit" href={`${loc}`} onClick={(e)=>handleClick(props?.crdData,props?.crdNames,loc,e)}>
       {props?.dpName[index]}
     </Link>
      })}
  </Breadcrumbs>
}

function ActivityTypes(props) {
    return (
        props?.AccTypes?.length>0 ? 
            <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2.2}>
          {props?.AccTypes&&props?.AccTypes.map((itm,index)=>{
            let tempKey = activityIds[itm?.activitytypeid]?.ImgActivity
            activityIds[itm?.activitytypeid][`${tempKey}`] = itm?.activityname
            return(<Grid   style={{cursor:'pointer'}} item xs={2.5}>
              <span style={{height:"100%",width:'100%',backgoundColor:'red'}}>
                <Activity enable={itm?.isenabled} ActivityId={activityIds[itm?.activitytypeid]?.ImgActivity} activitydetails={itm}  style={{backgroundColor: (itm?.isenabled)?'white':'lightgrey',borderColor:'#DCE8EB',cursor:(itm?.isenabled)?'pointer':'not-allowed'}}><ActivityCardView enable={itm?.isenabled} imgId = {itm?.activitytypeid} key={index} displyname={itm?.activityname}/></Activity>
              </span>
            </Grid>
          )})}
          </Grid>
        </Box> : <LoadWhile status={props.loadWhile}/>
      );
}
export default ActivityTypes
